import React, { useEffect, useState, Fragment } from 'react'
import { Link } from '@reach/router'
import { getDistance } from 'geolib'
import { Grid, Responsive, Dropdown, Card, Icon } from 'semantic-ui-react'
import { DatesRangeInput } from 'semantic-ui-calendar-react'
import _ from 'lodash'
import { api_url } from '../../enum'

const metersToMiles = 1 / 1609.344

const classStats = {
  unreleased: -100,
  available: 9999,
  cancelled: 180,
  completed: 148
}

const classTypesEnum = {
  monthly: 116,
  pickup: 182,
  assembled: 115,
  private: 117,
  couples: 126,
  vegetarian: 139
}

const getGeo = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords
        resolve([{ latitude, longitude }, undefined])
      },
      () => {
        resolve([undefined, new Error('Location could not be determined.')])
      }
    )
  })
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const daysAbbrev = ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']

const screenWidths = [330, 576, 768, 992, 1200, 1441, 2118, 2800]

const getClassStatus = product => {
  if (product.status === 'draft') {
    if (product.tags.find(t => t.name === classStats.cancelled)) return classStats.cancelled
    if (product.categories.find(t => t.name === classStats.completed)) return classStats.completed
    return classStats.unreleased
  }
  return classStats.available
}

const parseSku = sku => {
  const split = sku.split('-')
  if (split.length < 3) return {}
  let [year, monthday, locationCode] = split,
    assembled = ''
  if (locationCode === 'AS') [year, monthday, assembled, locationCode] = sku.split('-')
  year = `20${year}`
  const month = parseInt(monthday.substring(0, 2), 10)
  const day = parseInt(monthday.substring(2, 4), 10)
  const fecha = `${month}/${day}/${year}`
  const date = new Date(fecha)
  let daysleft = (date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  daysleft = Math.floor(daysleft)
  return {
    locationCode,
    month,
    day,
    date,
    year,
    assembled,
    daysleft
  }
}

const toTitle = txt => txt.replace(/(^|\s)\S/g, t => t.toUpperCase())

const classTypes = [
  ...Object.keys(classTypesEnum).map(t => ({ key: t, text: toTitle(t), value: classTypesEnum[t] }))
]

const days = [
  { key: 'monday', text: 'Monday', value: 1 },
  { key: 'tuesday', text: 'Tuesday', value: 2 },
  { key: 'wednesday', text: 'Wednesday', value: 3 },
  { key: 'thursday', text: 'Thursday', value: 4 },
  { key: 'friday', text: 'Friday', value: 5 },
  { key: 'saturday', text: 'Saturday', value: 6 }
]

const distances = [
  { key: '5', text: '5 Miles', value: 5 },
  { key: '10', text: '10 Miles', value: 10 },
  { key: '20', text: '20 Miles', value: 20 },
  { key: '30', text: '30 Miles', value: 30 },
  { key: '45', text: '45 Miles', value: 45 },
  { key: '80', text: '80 Miles', value: 80 },
  { key: '100', text: '100 Miles', value: 100 },
  { key: '120', text: '120 Miles', value: 120 }
]

const convertZip = zip => {
  const first2 = zip.substring(0, 2)
  const table = {
    84: 'UT',
    83: 'ID'
  }
  return table[first2]
}

const calcColumns = width => {
  const cardsWide = [1, 2, 3, 4, 5, 6, 8, 10]
  let screenBreakPoint = cardsWide.length
  while (width < screenWidths[screenBreakPoint - 1]) {
    screenBreakPoint -= 1
  }

  return cardsWide[screenBreakPoint - 1]
}

const Shop = props => {
  const [errors, setErrors] = useState([])

  const [locations, setLocations] = useState([])
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])

  const [columns, setColumns] = useState(
    calcColumns(typeof window !== 'undefined' ? window.innerWidth : 800)
  )
  const [locationFilter, setLocationFilter] = useState([])
  const [classTypeFilter, setClassTypeFilter] = useState([])
  const [daysfilter, setDaysfilter] = useState([])
  const [date, setDate] = useState('')
  const [miles, setMiles] = useState(30)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // eslint-disable-next-line no-use-before-define
  useEffect(() => calcProductFilter(), [locationFilter, classTypeFilter, daysfilter, date])
  // eslint-disable-next-line no-use-before-define
  useEffect(() => setLocationFilter(calcLocationsFromMiles(miles)), [miles])

  const calcLocationsFromMiles = (m, xLocations = locations) =>
    xLocations.filter(l => l.distance <= m).map(l => l.Code)

  const getData = async () => {
    // eslint-disable-next-line no-use-before-define
    const [rtnLocs = [], errLoc] = await fatch(`${api_url}/locations`)
    if (errLoc) setErrors([...errors, errLoc.message])

    const [myLocation, mylocError] = await getGeo()
    if (mylocError) setErrors([...errors, mylocError.message])

    const newLocations = _.orderBy(
      rtnLocs
        .filter(l => !l.name.includes('-'))
        .map((l, i) => ({
          ...l,
          key: l.Code,
          text: l.name,
          value: l.Code,
          distance:
            l.lat && l.long && myLocation
              ? getDistance(myLocation, { latitude: l.lat, longitude: l.long }) * metersToMiles
              : Number.MAX_VALUE
        })),
      ['distance'],
      ['asc']
    )

    setLocations(newLocations)
    const lFilter = calcLocationsFromMiles(30, newLocations)
    setLocationFilter(lFilter)
    // console.log(lFilter)
    // setClassTypeFilter(["regular", "assembled"]);
    const [rtnProducts = [], errProducts] = await fatch(`${api_url}/products_short`)
    if (errProducts) setErrors([...errors, errProducts.message])
    const newProducts = rtnProducts.map(product => {
      const { sku, stock_quantity: stockQuantity } = product
      const { locationCode, month, day, date, year, assembled, daysleft } = parseSku(sku)

      // get location data
      let location = rtnLocs.find(l => l.Code == locationCode)
      let state = location ? location.Address.split(' ').pop() : ''
      state = isFinite(state) ? convertZip(state) : state
      // console.log({location, locationCode, rtnLocs})

      const soldOut = daysleft <= 0 || stockQuantity == 0
      return {
        ...product,
        year,
        month,
        day,
        daysleft,
        dayofweek: date?.getDay(),
        date,
        epoch: date?.getTime(),
        assembled,
        locationCode,
        locationName: location?.name,
        state,
        status: getClassStatus(product),
        soldOut
      }
    })
    setProducts(newProducts)
    // eslint-disable-next-line no-use-before-define
    calcProductFilter({ lFilter, newProducts })
  }

  const calcProductFilter = (calcProductFilterData = {}) => {
    const { lFilter = locationFilter, newProducts = products } = calcProductFilterData
    // console.log({ calcProductFilterData })
    setFilteredProducts(
      _.orderBy(
        newProducts.filter(p => {
          const { locationCode, dayofweek, classType, epoch, tags } = p

          // Locations Calculation
          const lFilterOverride = lFilter
          const filteredByLocation =
            lFilterOverride.length === 0 ? false : lFilterOverride.includes(locationCode)

          // Date Range Calculation
          const [startString, endString] = date ? date.split(/ - /) : [undefined, undefined]
          const start = new Date(startString).getTime()
          const end = new Date(endString).getTime()
          const filteredByDateRange =
            startString && endString ? epoch >= start && epoch <= end : true

          // Day of Week Calculation
          const filteredByDayOfWeek =
            daysfilter.length === 0 ? true : daysfilter.includes(dayofweek)

          // ClassType Calculation
          const tagsIds = tags.map(({ id }) => id)
          const filteredByClassType =
            classTypeFilter.length === 0
              ? true
              : classTypeFilter.map(cType => tagsIds.includes(cType)).includes(true)

          // return  status === "published" && catalog_visibility === "public" && filteredByLocation
          return (
            filteredByLocation && filteredByDayOfWeek && filteredByDateRange && filteredByClassType
          )
        }),
        ['locationCode', 'epoch'],
        ['asc', 'asc']
      )
    )
  }

  const setDateRange = (event, { value }) => {
    console.log(value)
    setDate(value)
  }

  const handleResize = e => {
    const tmpCol = calcColumns(e.target.innerWidth)
    if (tmpCol !== columns) setColumns(tmpCol)
  }

  const itsMobile = columns <= 3
  return (
    <Grid style={{ padding: '0 16px' }}>
      <Grid.Column
        width={itsMobile ? 16 : 3}
        style={{ paddingRight: 16, ...(itsMobile && { display: 'flex' }) }}
      >
        <Responsive onUpdate={handleResize} />
        <DatesRangeInput
          name="date"
          dateFormat="MM-DD-YYYY"
          placeholder="From - To"
          value={date}
          iconPosition="left"
          onChange={setDateRange}
          style={{ width: '-webkit-fill-available', paddingBottom: 16 }}
          pickerStyle={{ background: 'transparent', border: 'none' }}
        />
        <Dropdown
          icon="angle down"
          iconPosition="right"
          placeholder="Miles to"
          style={{ paddingBottom: 16 }}
          fluid
          options={distances}
          value={miles}
          onChange={(e, t) => {
            setMiles(t.value)
          }}
        />
        <Dropdown
          icon="angle down"
          iconPosition="left"
          placeholder="Locations"
          style={{ paddingBottom: 16 }}
          multiple
          fluid
          options={locations.map(l => ({
            key: l.key,
            text: l.text,
            value: l.value
          }))}
          value={locationFilter}
          onChange={(e, t) => {
            setLocationFilter(t.value)
          }}
        />
        <Dropdown
          placeholder="Class Type"
          style={{ paddingBottom: 16 }}
          icon="angle down"
          fluid
          multiple
          options={classTypes}
          value={classTypeFilter}
          onChange={(e, t) => {
            setClassTypeFilter(t.value)
          }}
        />
        <Dropdown
          placeholder="Day of the Week"
          style={{ paddingBottom: 16 }}
          icon="angle down"
          fluid
          multiple
          options={days}
          value={daysfilter}
          onChange={(e, t) => {
            setDaysfilter(t.value)
          }}
        />
      </Grid.Column>
      <Grid.Column width={columns <= 2 ? 16 : 13}>
        {filteredProducts.length ? (
          <Card.Group itemsPerRow={columns}>
            {filteredProducts.map(product => (
              <ClassCard key={product.sku} {...product} />
            ))}
          </Card.Group>
        ) : (
          <SelectSomethingOnTheLeft itsMobile={itsMobile} />
        )}
      </Grid.Column>
    </Grid>
  )
}

const SelectSomethingOnTheLeft = props => (
  <>
    {props.itsMobile ? (
      <div>Select a Location from above</div>
    ) : (
      <div>Select a Location on the left</div>
    )}
  </>
)

const fatch = (url, opt) => {
  return new Promise(resolve => {
    return fetch(url, opt)
      .then(response => response.json())
      .then(json => resolve([json, undefined]))
      .catch(e => {
        resolve([undefined, e])
      })
  })
}

const ClassCard = props => {
  const {
    sku,
    stock_quantity: stockQuantity,
    locationName,
    dayofweek,
    day,
    month,
    daysleft,
    soldOut
  } = props

  // const accent = "#23cb64";

  const accent = '#b5cc18'
  // const accent = "green";

  return (
    <Card
      style={{
        borderRadius: 0,
        color: '#949494',
        cursor: 'pointer',
        boxShadow: '#bfbfbf 3px 3px 8px'
      }}
      color={accent}
    >
      <Link to={`/class/${sku}`} style={{ color: '#949494' }}>
        <Card.Content
          style={{
            background: accent,
            borderRadius: '0px',
            paddingBottom: 12,
            paddingTop: 5,
            fontSize: '1.5rem',
            maxHeight: 32
          }}
        >
          <Card.Header
            style={{
              borderRadius: 0,
              color: '#FFF',
              fontFamily: 'oswald',
              fontWeight: 400,
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '2px'
            }}
          >
            {daysAbbrev[dayofweek - 1]}. {months[month - 1]}
          </Card.Header>
        </Card.Content>
        <Card.Content style={{ flexGrow: 'unset' }}>
          <div
            style={{
              fontFamily: 'oswald',
              fontWeight: 800,
              fontSize: '5rem',
              lineHeight: '4rem',
              marginTop: 4,
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
          >
            {day}
          </div>
          <div
            style={{
              fontFamily: 'oswald',
              fontWeight: 200,
              fontSize: '1.3rem',
              lineHeight: '2rem',
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: 2,
              marginTop: '4px'
            }}
          >
            {locationName}
          </div>
          <div
            style={{
              fontFamily: 'oswald',
              fontWeight: 400,
              color: '#000',
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '1px',
              marginBottom: 8
            }}
          >
            {!soldOut ? `${daysleft} DAYS LEFT` : 'SOLD OUT'}
          </div>
        </Card.Content>
      </Link>

      <Card.Content extra>
        <Icon name="user" />
        {soldOut ? '0' : stockQuantity} spots remaining
      </Card.Content>
    </Card>
  )
}

export default Shop
